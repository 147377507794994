
.hotGood-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .analysis-left {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;
        border-right: 1px solid #EAEAEA;
    }
    .eCharts-item {
        margin-top: 30px;
        padding: 0 20px;
        &:first-child {
            margin-top: 0;
        }
        .item-title {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            .toggle-day {
                .day {
                    cursor: pointer;
                    color: #999;
                    &.current, &:hover {
                        color: #42D7AB;
                    }
                }
            }
        }
        .item-content {
            margin-top: 10px;
            width: 100%;
            height: 300px;
        }
    }
}
.gender-distribution {
    display: flex;
    flex-direction: column;
    .title {
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }
    .distribution-chart {
        .chart-icon {
            display: flex;
            justify-content: space-between;
            color: #34aa7f;
            margin-top: 10px;
            & i {
                font-size: 24px;
            }
        }
        .chart-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 20px;
            margin-top: 10px;
            .man {
                background-color: #34aa7f;
            }
            .woman {
                flex: 1;
                background-color: #46dbab;
            }
        }
        .legend {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .legend-man {
                display: flex;
                flex-direction: column;
            }
            .legend-woman {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
.class-list {
    width: 50%;
    height: 1%;
    flex: 1;
    .analysis-right {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            padding-right: 5px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            cursor: pointer;
            &.current {
                color: #0000FF;
            }
        }
        .content {
            flex: 1;
            overflow: auto;
            padding-right: 5px;
        }
        .area-distribution {
            flex: 1;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            height: calc(100% - 66px);
            .area-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                .area-name {
                    width: 50px;
                    white-space: nowrap;
                    overflow: hidden;
                }
                ::v-deep .el-progress {
                    width: 80%;
                    padding: 0 10px;
                }
                ::v-deep .el-progress-bar__inner {
                    background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                }
            }
        }
    }
}

.big-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.industry-box {
    display: flex;
    .industry-item {
        width: 1%;
        flex: 1;
        .industry-title {
            .text {
                line-height: 1;
                margin-bottom: 10px;
            }
        }
        .industry-column {
            height: 350px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                width: 1px;
                height: 100%;
                background: #DDDFEB;
            }
            .column-box {
                position: absolute;
                top: 35px;
                left: 0;
                right: 0;
                bottom: 0;
            }
            .column {
                position: absolute;
                left: 1px;
                bottom: 0;
                width: 40px;
                background: #34aa7f;
            }
        }
        &:nth-of-type(even) {
            .industry-column {
                .column {
                    background: #46dbab;
                }
            }
        }
    }
}

.no-data {
    height: 300px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
}


.comment-box {
    margin-bottom: 20px;
    padding-bottom: 25px;
    ::v-deep & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .comment-list {
        padding: 0 20px;
        max-height: 425px;
        display: flex;
        flex-wrap: wrap;
        .comment-item {
            width: calc(50% - 10px);
            display: flex;
            align-items: center;
            box-sizing: border-box;
            margin-bottom: 20px;
            &:nth-of-type(even) {
                margin-left: 20px;
            }
            .comment-left {
                width: 63.68421px;
                height: 88px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .comment-right {
                width: 1%;
                flex: 1;
                margin-left: 10px;
                .title {
                    color: #999;
                }
                .bottom {
                    display: flex;
                    align-items: center;
                    background: #f1f0fe;
                    margin-top: 10px;
                    padding: 10px;
                    .left {
                        text-align: center;
                        .iconfont {
                            color: #e8828b;
                        }
                        .text {
                        }
                    }
                    .center {
                        margin: 0 10px 0 20px;
                        width: 1%;
                        flex: 1;
                    }
                }
            }

        }
    }
}
