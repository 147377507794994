
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #1E33E3;
    border-color: #1E33E3;
}
.dy-header {
    display: flex;
    align-items: center;
    .rank-type {
        margin-right: 20px;
    }
    .rank-date {
        margin-right: 20px;
    }
    .search-input {
        width: 300px;
    }
}
.good-broad-caster {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    .goods-detail {
        display: flex;
        align-items: center;
        .goods-img {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            border-radius: 50%;
            overflow: hidden;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-info {
            width: 1%;
            flex: 1;
        }
    }
    .goods-video-detail {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #333;
        &:hover {
            color: #1E33E3;
        }
        .goods-img {
            width: 64px;
            height: 89px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-info {
            width: calc(100% - 74px);
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            a {
                color: #1A1A1A;
            }
        }
    }
}
.pages-center {
    width: 100%;
    flex: 1 1 0%;
    height: calc(100% - 52px);
}

.category-list {
    display: flex;
    margin-top: 20px;
    .cate-left {
        /*width: 70px;*/
        text-align-last: justify;
    }
    .cate-content {
        width: 1%;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;
        .cate-item {
            padding: 0 8px;
            margin: 0 0 10px 12px;
            border: 1px solid transparent;
            cursor: pointer;
            &.current {
                border-color: #1E33E3;
                color: #1E33E3;
            }
        }
    }
}
