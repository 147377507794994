
::v-deep p {
    margin: 0;
}
.goodDetail-container {
    height: 100%;
    display: flex;
    .goodDetail-left {
        width: 1%;
        flex: 1;
        display: flex;
        flex-direction: column;
        .goodDetail-intro {
            display: flex;
            background: #fff;
            padding: 20px;
            .good-cover {
                width: 130px;
                height: 130px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .good-detail {
                width: 1%;
                flex: 1;
                margin-left: 20px;
                display: flex;
                justify-content: space-between;
                position: relative;
                .d-left {
                    width: 1%;
                    flex: 1;
                    .title {
                        font-size: 16px;
                        margin-bottom: 20px;
                        color: #333;
                        &:hover {
                            color: #1E33E3;
                        }
                    }
                    .line {
                        margin-top: 10px;
                        .tag {
                            display: inline-block;
                            color: #999;
                            min-width: 40%;
                            margin-right: 2%;
                        }
                    }
                }
                .d-right {
                    margin-left: 100px;
                    .price-now {
                        color: #FF0101;
                        font-size: 18px;
                    }
                    .price-old {
                        color: #666;
                        margin-left: 24px;
                        text-decoration: line-through;
                    }
                }
                .update-data {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    text-align: right;
                    .button {
                        margin-bottom: 5px;
                        cursor: pointer;
                        .iconfont {
                            margin-right: 4px;
                        }
                    }
                    .text {
                        color: #999;
                    }
                }
            }
        }
        .goodDetail-tabs {
            height: 1%;
            flex: 1;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            ::v-deep .el-tabs__header {
                margin-bottom: 0;
                border-bottom: none;
                .el-tabs__nav {
                    border-color: transparent;
                    .el-tabs__item {
                        border-color: transparent;
                        height: 50px;
                        line-height: 50px;
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;
                        font-size: 16px;
                        color: #858EA6;
                        &.is-active {
                            color: #0824CE;
                            background: #fff;
                        }
                        &:hover {
                            color: #0824CE;
                        }
                    }
                }
            }
            ::v-deep .el-tabs__content {
                height: 1%;
                flex: 1;
                background: #fff;
                .el-tab-pane {
                    height: 100%;
                }
            }
        }
    }
}
