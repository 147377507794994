
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #1E33E3;
    border-color: #1E33E3;
}
.hotGood-container {
    /*height: calc(100% - 40px);*/
    margin: 20px 0;
    /*::v-deep .el-scrollbar__wrap {*/
    /*    overflow-x: hidden;*/
    /*}*/
    .eCharts-item {
        margin-top: 20px;
        padding: 0 20px;
        &:first-child {
            margin-top: 0;
        }
        .item-title {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            .toggle-day {
                .day {
                    cursor: pointer;
                    color: #999;
                    &.current, &:hover {
                        color: #42D7AB;
                    }
                }
            }
        }
        .item-content {
            /*background: #ffa72a;*/
            margin-top: 10px;
            width: 100%;
            height: 340px;
        }
        .select-title {
            font-size: 16px;
        }
        .video-box {
            display: flex;
            margin-top: 20px;
            .video-item {
                width: calc(50% - 10px);
                .video-title {
                    font-size: 16px;
                }
            }
        }
    }
}
.no-data {
    height: 200px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
}
