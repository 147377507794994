
.promoteGood-container {
    padding: 20px;
    /*height: calc(100% - 40px);*/
    /*display: flex;*/
    /*flex-direction: column;*/
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #1E33E3;
    border-color: #1E33E3;
}
.dy-header {
    display: flex;
    align-items: center;
    .rank-type {
        margin-right: 20px;
    }
    .rank-date {
        margin-right: 20px;
    }
    .search-input {
        width: 300px;
    }
}
// 表格内样式
.listTable {
    .goods-wrapper {
        display: flex;
        align-items: center;
        .user-cover {
            width: 24px;
            height: 24px;
            overflow: hidden;
            background: #f3f3f6;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-cover {
            width: 36px;
            height: 52px;
            overflow: hidden;
            background: #f3f3f6;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-detail {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            color: #333;
        }
        .hot {
            &:after {
                content: '、';
            }
            &:last-child {
                &:after {
                    content: none;
                }
            }
        }
        &:hover {
            .goods-title {
                color: #1E33E3;
            }
        }
    }
    .author-name {
        white-space: nowrap;
        color: #2338E6;
    }
}

.card-box {
    margin-top: 10px;
    .card-title {
        font-size: 16px;
    }
    .card-content {
        display: flex;
        margin-top: 10px;
        background: #f2f2f2;
        .card-item {
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 120px;
            .text {
                font-size: 16px;
                color: #666;
            }
            .num {
                margin-top: 10px;
                font-weight: 700;
                font-size: 20px;
            }
        }
    }
}
